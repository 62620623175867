import React from 'react'
import styled from 'styled-components'

import { Container } from 'common/UI'
import { IconOptions } from 'common/UI/Icon'
import { RichTextButtonStoryblok } from 'common/types'
import { hasValidLink } from 'common/utils/content'
import { CMSLink } from 'modules/shared'

type Props = {
  block: RichTextButtonStoryblok
}

export const RichTextButton = ({
  block,
  ...props
}: Props): JSX.Element | null => {
  const { link, label, right_icon, left_icon, is_full, size, variant } = block

  if (!link || !hasValidLink(link)) {
    return null
  }

  return (
    <Container variant="typography" {...props}>
      <Wrapper>
        <CMSLink
          href={link}
          rightIcon={right_icon as IconOptions}
          leftIcon={left_icon as IconOptions}
          isFull={is_full}
          variant={variant}
          size={size}
        >
          {label}
        </CMSLink>
      </Wrapper>
    </Container>
  )
}

const Wrapper = styled.div`
  padding: 1.5rem 0;
`
